<template>
  <div class="mx-auto subcat-navigation world-of" :class="[`columns-${orderedCategory.length}`]">
    <div class="first-level-nav level-0 animate__animated animate__fadeIn animate__slideInUp" v-for="(category, index) in orderedCategory" :key="index" :class="[`nav-0-${index + 1}`, `animate__delay-0${index < 2 ? 2 + index : index + 1 }s`]">
      <h3 class="title animate__animated animate__fadeIn">{{category.name}}</h3>
      <ul class="level-1 nav flex-column justify-content-center">
        <li
          class="navbar-item animate__animated animate__fadeIn"
          v-for="(subcategory, subIndex) in sortedCat(category.childrenData, ['position'])"
          :key="subcategory.url_path"
          :class="[`animate__delay-0${subIndex}s`]"
        >
          <router-link
          :to="'listing' | localizedByNameCategories(subcategory.url_path, null, $store, $router)"
          class="nav-sublink bcm-link"
          >
            <span class="link-label">{{ subcategory.name }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="nav-images" :class="[`images-columns-${orderedCategory.length}`]">
      <NavImage :category="category" />
    </div>
  </div>
</template>

<script>
import LinkManager from 'theme/components/Header/SubMenu/Layout/LinkManager'
import NavImage from 'theme/components/Header/SubMenu/NavImage'
import sortBy from 'lodash/sortBy'
/* import uniqBy from 'lodash/uniqBy' */

export default {
  name: 'WorldOf',
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_MAGENTO
    }
  },
  computed: {
    orderedCategory () {
      return sortBy(this.category.childrenData, ['position'])
    }
  },
  methods: {
    sortedCat (cat, ord) {
      return sortBy(cat, [`${ord}`])
    },
    orderLinks (collectionLinks) {
      /* push 'view all' to the end of link list */
      const viewAll = collectionLinks.find(link => link.name.toLowerCase().includes('view all'))
      if (viewAll) {
        const orderedLinks = collectionLinks.filter(link => link !== viewAll)
        orderedLinks.push(viewAll)
        return orderedLinks
      }
      return collectionLinks
    }
  },
  components: {
    NavImage,
    LinkManager
  }
}
</script>
